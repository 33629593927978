import router from "@/router/index";
import cache from "@/utils/cache";
import store from "@/store/store";
import $ from 'jquery';
import Cache from "@/utils/cache";
import User from "@/utils/user";
import Version from '@/utils/version'
import {Toast} from "vant";

router.beforeEach((to,from,next) => {
    // 判断是否版本更新
    Version.versionUtil()
    if(!Cache.getIsLogin()){
         if(to.query.code){
             store.commit('M_LOGIN_LOADING', true)
             User.weaChatLogin(to.query.code, function (data){
                 store.commit('M_LOGIN_LOADING', false)
                 // 判断用户状态
                 if (data.openid) {
                     store.commit('M_LOGIN_POP', false)
                     store.commit('M_MOBILE_PHONE', true)
                 } else if (data.access_token) {
                     Toast('登录成功');
                     setTimeout(()=>{
                         location.replace(window.location.href)
                     },1500)
                 }else {
                     Toast('授权失败，请重新授权');
                 }
             })
         }
    }
    // 登录信息
    if (Cache.getIsLogin()) {
        // 判断是否更新用户信息
        if(to.meta.userUpdate){
            User.getUserInfo();
        }
    }
    if (to.query.invite_code){
        localStorage.setItem('invite_code',to.query.invite_code)
    }
    if(to.meta.Auth){
        if(cache.getIsLogin()){
            // 判断是否选择标签
            // let userInfo = store.getters.userInfo;
            // if(!userInfo.has_chose && userInfo.status_team && userInfo.status_team.operate_status == 3){
            //     next({path: '/'})
            // }else {
            //
            // }
            next();
        }else {
             next({path: '/'})
        }
    }
    // 设置title
    if(to.meta.title){
        store.commit('M_TITLE',to.meta.title)
    }
    next()
});
