<template>
  <div>
    <!--底部导航开始-->
    <div class="foot-height safety-height"></div>
    <div class="foot-wrap safety-height">
      <ul class="foot-nav-ul">
        <li v-for="(item,index) in navList" :key="index">
          <router-link :to="item.path" exact>
            <div>
              <img :src="item.active" class="nav-default-img" alt="">
              <img :src="item.inactive" class="nav-active-img" alt="">
              <p>{{ item.title }}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <!--底部导航结束-->
  </div>

</template>

<script>
import {ref} from 'vue';

export default {
  name: "TabBar",
  setup() {
    const active = ref(0);
    const navList = [
      {
        title: '首页',
        active: require('assets/images/nav/index.png'),
        inactive: require('assets/images/nav/index-selected.png'),
        path: '/'
      },
      {
        title: '任务',
        active: require('assets/images/nav/task.png'),
        inactive: require('assets/images/nav/task-selected.png'),
        path: '/task'
      },
      {
        title: '社区',
        active: require('assets/images/nav/community.png'),
        inactive: require('assets/images/nav/community-selected.png'),
        path: '/community'
      },
      {
        title: '商城',
        active: require('assets/images/nav/malls.png'),
        inactive: require('assets/images/nav/malls-selected.png'),
        path: '/malls'
      },
      {
        title: '我的',
        active: require('assets/images/nav/me.png'),
        inactive: require('assets/images/nav/me-selected.png'),
        path: '/user'
      },
    ]

    return {
      active,
      navList
    }

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;

/*底部导航开始*/
.foot-height {
  height: 98/$r;
}

.foot-wrap {
  width: 100%;
  height: 98/$r;
  background-color: #FFFFFF;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  border-top: 1px solid #E9E9E9;
}

.foot-wrap .foot-nav-ul {
  height: 100%;
  display: flex;
}

.foot-wrap .foot-nav-ul li {
  height: 100%;
  flex: 1;
}

.foot-wrap .foot-nav-ul li a {
  height: 100%;
  display: flex;
  justify-content: center;
}

.foot-wrap .foot-nav-ul li a img {
  width: 44/$r;
  height: 44/$r;
  margin: 12/$r auto 0 auto;
  display: block;
}

.foot-wrap .foot-nav-ul li a .nav-active-img {
  display: none;
}

.foot-wrap .foot-nav-ul li a p {
  font-weight: 500;
  margin-top: 8/$r;
  text-align: center;
  font-family: PingFang-SC-Medium;
  font-size: 20/$r;
  line-height: 24/$r;
  color: #ADB3B6;
}

.foot-wrap .foot-nav-ul li .nav-a .nav-default-img {
  display: none;
}

.foot-wrap .foot-nav-ul li .nav-a .nav-active-img {
  display: block;
}

.foot-wrap .foot-nav-ul li .nav-a p {
  color: #313A43;
}

/*底部导航结束*/

.router-link-active .nav-default-img {
  display: none !important;
}

.router-link-active .nav-active-img {
  display: block !important;
}

.router-link-active p {
  color: #FF6E3B !important;
}
</style>
