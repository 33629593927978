import urlEncode from 'urlencode';

export default {
    /*
    *   微信授权
    * */
    wechat() {
        let returnUrl = urlEncode(window.location.href);
        let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_ID + '&redirect_uri=' + returnUrl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
        location.replace(url)
    }

}
