import wx from 'weixin-js-sdk';
import http from '../http/request';
import handle from '../utils/handle';
import api from '../http/api';
import router from "@/router";
export default {

    /* 微信分享设置
    *  title 分享标题
    *  desc  分享描述
    *  imgUrl 分享图标地址
    * */
    wxShare(title,desc,imgUrl,invite_code){
        let share_url = location.href.split('#')[0];
        let data = {
            url: share_url
        }
        let url = api.Signature;
        http.post(url,data,false).then(result => {
            if (result.data.success) {
                let result_data = result.data.data;
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: process.env.VUE_APP_ID, // 必填，公众号的唯一标识
                    timestamp: result_data.timestamp , // 必填，生成签名的时间戳
                    nonceStr:  result_data.nonceStr, // 必填，生成签名的随机串
                    signature: result_data.signature,// 必填，签名
                    jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareWeibo'], // 必填，需要使用的JS接口列表  'updateAppMessageShareData','updateTimelineShareData','onMenuShareWeibo',
                    openTagList: ['wx-open-launch-weapp']
                });

                wx.ready(function () {
                    //分享给朋友
                    wx.updateAppMessageShareData({
                        title: title, // 分享标题
                        desc: desc, // 分享描述
                        link: window.location.origin + '?invite_code=' + invite_code, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                    // 分享到朋友圈
                    wx.updateTimelineShareData({
                        title: title, // 分享标题
                        link: window.location.origin + '?invite_code=' + invite_code, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                    wx.onMenuShareWeibo({
                        title: title, // 分享标题
                        desc: desc, // 分享描述
                        link: window.location.origin + '?invite_code=' + invite_code, // 分享链接
                        imgUrl: imgUrl, // 分享图标
                        success: function () {
                            // 用户确认分享后执行的回调函数
                        },
                        cancel: function () {
                            // 用户取消分享后执行的回调函数
                        }
                    });
                });
            }
        })
    }
}
