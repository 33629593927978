import { createStore } from 'vuex'

const store = createStore({
    state: {
        userInfo: localStorage.getItem('userInfo'),     // 用户信息
        openid: '',
        errorText: {
            code_num: '404',
            error_text: '很抱歉！没有你要访问的页面…'
        },
        title: '',      // 页面title,
        mallLeave: null,  // 积分等级
        mallSide: null,    // 积分侧边导航
        login_pop_status: false,    // 登录提示弹框
        mobile_phone_static: false, // 手机号授权绑定
        login_loading_show: false,  // 登录加载中
        act:'1',                   //激活标签
        acts:'1',                   //激活标签
    },
    mutations: {
        // 设置用户信息
        M_USER_INFO(state,data){
            state.userInfo = data
        },
        // 设置 openid
        M_USER_OPENID(state,data){
            state.openid = data
        },
        // 设置 报错状态文字
        M_ERROR_TEXT(state,data){
            state.errorText = data
        },
        // 修改返回title
        M_TITLE(state,data){
            state.title = data
        },
        // 修改积分等级
        M_MALL_LEAVE(state,data){
            state.mallLeave = data
        },
        // 修改积分侧边导航
        M_MALL_SIDE(state,data){
            state.mallSide = data
        },
        // 提示登录弹框
        M_LOGIN_POP(state, data) {
            state.login_pop_status= data
        },
        // 手机号绑定弹框
        M_MOBILE_PHONE(state, data) {
            state.mobile_phone_static= data
        },
        // 加载提示弹框
        M_LOGIN_LOADING(state, data) {
            state.login_loading_show= data
        },
        //修改激活标签
        M_ACT(state, data) {
            state.act= data
        },
        M_ACTS(state, data) {
            state.acts= data
        },
    },
    actions: {
        // 用户信息
        A_USER_INFO(context,data){
            context.commit('M_USER_INFO',data)
        },
        // 设置   openid
        A_USER_OPENID(context,data){
            context.commit('M_USER_OPENID',data)
        },
        // 设置 报错状态文字
        A_ERROR_TEXT(context,data){
            context.commit('M_ERROR_TEXT',data)
        },
        // 修改积分等级
        A_MALL_LEAVE(context,data){
            context.commit('M_MALL_LEAVE',data)
        },
        // 修改积分侧边导航
        A_MALL_SIDE(context,data){
            context.commit('M_MALL_SIDE',data)
        }
    },
    getters: {
        userInfo: state => {
            return JSON.parse(state.userInfo)
        },
        openid: state => {
            return state.openid
        },
        errorText: state => {
            return state.errorText
        },
        title: state => {
            return state.title
        },
        mallLeave: state => {
            return state.mallLeave
        },
        mallSide: state => {
            return state.mallSide
        },
        loginPopStatus: state => {
            return state.login_pop_status
        },
        mobilePhone: state => {
            return state.mobile_phone_static
        },
        loginLoading: state => {
            return state.login_loading_show
        },
        act: state => {
            return state.act
        },
        acts: state => {
            return state.acts
        },
    }
})

export default store