<template>
  <div id="root" ref="app">
    <BulletFrame v-if="$cache.getIsLogin()"></BulletFrame>
    <nav-bar v-if="$route.meta.navBar" ></nav-bar>

    <router-view v-slot="{ Component }" v-if="$route.meta.keepAlive">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
<!--    <keep-alive>-->
<!--      <router-view v-if="$route.meta.keepAlive" />-->
<!--    </keep-alive>-->
    <router-view v-if="!$route.meta.keepAlive" />
    <tab-bar v-if="$route.meta.tabBar"></tab-bar>

    <login-bounced></login-bounced>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import TabBar from "@/components/TabBar";
import BulletFrame from "@/components/BulletFrame";
import LoginBounced from "./components/LoginBounced";

export default {
  name: 'App',
  components: {
    NavBar,
    TabBar,
    BulletFrame,
    LoginBounced
  },
  // mounted() {
  //   window.localStorage.setItem('scanUrl',location.href.split('#')[0]);
  // },
  // watch: {
  //   $route(){
  //     this.$refs.app.scrollTo(0,0)
  //   }
  // }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "~assets/scss/public/public.css";
</style>
