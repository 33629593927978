import ls from './localStorage'
import api from '../http/api';
import http from '../http/request';
import cache from "@/utils/cache";

// 刷新Token
if (ls.getItem('expires_in') && ls.getItem('refresh_token')) {
    let expires_in = ls.getItem('expires_in');
    if (new Date().getTime() >= expires_in) {
        let data = {
            grant_type: 'refresh_token',
            client_id: 1,
            client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
            refresh_token: ls.getItem('refresh_token')
        };
        http.post(api.Update, data).then(res => {
            if (res.data.success) {
                cache.setLoginInfo(res.data.data);
                cache.setExpiresIn(res.data.data.expires_in);
            }
        })
    }
}