import $ from "jquery";
import carbon from "@/utils/carbon";

const api = require('../http/api');
const http = require('../http/request');

export default {
    /*
* 设置title，
* keywords
* description
* */
    siteConfiguration(title, key, descriptions) {
        setTimeout(()=>{
            if (title != '' && title != null) {
                document.title = title + '-' + '酸谈社群';
            }
            if (key != '' && key != null) {
                document.querySelector('meta[name="keywords"]').setAttribute('content', key);
            }
            if (descriptions != '' && descriptions != null) {
                document.querySelector('meta[name="description"]').setAttribute('content', descriptions);
            }
        },200)
    },

    /*
    * 任务数组完成状态排序
    * arr 排序数组
    * */
    taskSort(arr){
        let sucArr = [];    // 完成状态的
        let finArr = [];    // 未操作的
        let pastArr = [];   // 过期的
        let rejectArr = []; // 驳回的
        let auditArr = [];  // 待审核

        if(arr){
            for (let i=0;i<arr.length;i++){
                if(arr[i].status == 1){
                    sucArr.push(arr[i])
                }else if(arr[i].code == 'limited' && !arr[i].can_do && arr[i].status != 1 ){
                    pastArr.push(arr[i])
                }else if(arr[i].status == 0){
                    auditArr.push(arr[i])
                } else if(arr[i].status == -1){
                    rejectArr.push(arr[i])
                } else {
                    finArr.push(arr[i])
                }
            }
        }
        return finArr.concat(auditArr).concat(rejectArr).concat(sucArr).concat(pastArr)
    },
    /*
    * 设置元素最小高度
    *
    * */
    domMinHeight(){
        // 全局设置
        let body_h = $(window).height();
        let foot_h = 0;
        let nav_bar_h = 0;
        if($('.foot-height').height()){
            foot_h = $('.foot-height').height()
        }
        if($('.nav-bar-wrap').height()){
            nav_bar_h = $('.nav-bar-wrap').height()
        }
        if($('.grey-bg')){
            $('.grey-bg').css("min-height",body_h - foot_h - nav_bar_h )
        }
    },

}
