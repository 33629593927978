<template>
    <div>
        <div class="error-con vertical-center">
            <!--404图片-->
            <img class="error-img" v-if="errorText.code_num == '404'" src="../../assets/images/user/error.png" alt="">
            <!--500图片-->
            <img class="error-img" v-if="errorText.code_num == '500'" src="../../assets/images/user/error1.png" alt="">   
            <img class="error-img" v-if="errorText.code_num == '602'" src="../../assets/images/user/error6.png" alt="">
            <div class="error-tip">{{errorText.error_text}}</div>
            <div class="error-black"><span class="error-time">{{num}}</span> 秒后将返回到首页</div>
        </div>
    </div>
</template>

<script>
export default {
  name: "error",
  data(){
    return{
      time: 3,
      num: 3,
      timer: null
    }
  },
  created() {
     this.$store.commit('M_ACT','1')
     this.$store.commit('M_ACTS','1')
    this.timer = setInterval(()=>{
      if(this.time == 0){
        clearInterval(this.timer);
        this.$router.replace({path: '/'})
      }
      this.time--;
      this.num = this.time;
    },1000)
  },
  methods: {
  },
  computed: {
    errorText() {
      return this.$store.getters.errorText;
    }
  }
}
</script>

<style scoped lang="scss">
$r: 750/16/1rem;
.error-con{
    flex-direction: column;
    margin-top: 163/$r;
    font-size: 24/$r;
    font-weight: 500;
    color: #4D4D4D;
    line-height: 24/$r;
    .error-img{
        width: 360/$r;
        height: 239/$r;
    }
    .error-tip{
        margin-top: -16/$r;
    }
    .error-black{
        margin-top: 14/$r;
        .error-time{
            color: #FF6B29;
        }
    }

}
</style>