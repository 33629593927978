export default {
    /*版本控制*/
    versionUtil(){
        const version = process.env.VUE_APP_VERSION;
        const clientVersion =localStorage.getItem('_version_');
        if(clientVersion !== version){
            localStorage.setItem('_version_', version)
            window.location.reload()
        }
    }
}
