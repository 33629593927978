import taskImg from '../assets/images/task/taskImg.png';
const ossCdn = 'https://resources.suantan.net/'
export default {
    avatar: 'https://static.helixlife.cn/edu/home/images/avatar.png',           // 默认头像
    taskImg: taskImg,    // 默认任务图片
    teamImg: taskImg,    // 默认战队图片
    ossCdn: ossCdn,
    LeadImg: ossCdn + 'images/sour-talk-lead.png',
    Version: '1.0.0',   // 版本控制
}
