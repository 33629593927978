export default [
    // 首页
    {
        path: '/',
        name: 'home',
        component: () => import('pages/home/Home'),
        meta: {
            tabBar: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    //排行榜
    {
        path: '/ranking/:id?',
        name: 'ranking',
        component: () => import('pages/user/ranking/ranking'),
        meta: {
            navBar: true,
            title: '排行榜',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新

        }
    },
    //学习小组
    {
        path: '/study',
        name: 'study',
        component: () => import('pages/user/study/index'),
        meta: {
            navBar: true,
            title: '学习小组',
            Auth: true
        }
    },
    //学习小组详情
    {
        path: '/study/show/:id',
        name: 'studyShow',
        component: () => import('pages/user/study/show/show'),
        meta: {
            navBar: true,
            title: '',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 任务
    {
        path: '/task',
        name: 'task',
        component: () => import('../pages/task/index'),
        meta: {
            tabBar: true,
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 上传任务
    {
        path: '/task/show/:package_id/:id',
        name: 'taskShow',
        component: () => import('../pages/task/show/show'),
        meta: {
            navBar: true,
            title: '上传任务',
            Auth: true
        }
    },
    // 签到
    {
        path: '/task/sign',
        name: 'taskSign',
        component: () => import('pages/user/sign/sign'),
        meta: {
            navBar: true,
            title: '签到',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 商城
    {
        path: '/malls',
        name: 'malls',
        component: () => import('../pages/malls/index/index'),
        meta: {
            tabBar: true,
            Auth: false,
            keepAlive: true
        }
    },
    // 商城列表
    {
        path: '/malls/show/:uuid',
        name: 'mallsShow',
        component: () => import('pages/malls/show/show'),
        meta: {
            navBar: true,
            title: '',
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
            // keepAlive: true
        }
    },
    // 订单兑换成功
    {
        path: '/order/succeed',
        name: 'orderSucceed',
        component: () => import('pages/user/order/succeed/succeed'),
        meta: {
            title: '兑换成功',
            Auth: true
        }
    },
    // 训练营加入班级
    {
        path: '/courses/trainings/group/:id',
        name: 'trainings.group',
        component: () => import('pages/user/course/camp/JoinClass'),
        meta: {
            navBar: true,
            title: '报名成功',
            Auth: true
        }
    },
    // 订单
    {
        path: '/order/confirm',
        name: 'orderConfirm',
        component: () => import('pages/user/order/confirm/confirm'),
        meta: {
            navBar: true,
            title: '订单',
            Auth: true
        }
    },

    // 我的
    {
        path: '/user',
        name: 'user',
        component: () => import('pages/user/me'),
        meta: {
            tabBar: true,
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 任务通知
    {
        path: '/user/task',
        name: 'taskManagement',
        component: () => import('pages/user/task-management/index'),
        meta: {
            navBar: true,
            title: '任务通知',
            Auth: true,
            backUrl: '/user'
        }
    },
    // 任务通知详情
    {
        path: '/user/task/show/:id',
        name: 'taskManagementShow',
        component: () => import('pages/user/task-management/show/show'),
        meta: {
            navBar: true,
            title: '任务通知详情',
            Auth: true
        }
    },
    // 战队通知
    {
        path: '/user/notice',
        name: 'notice',
        component: () => import('pages/user/notice/notice'),
        meta: {
            navBar: true,
            title: '战队通知',
            Auth: true,
            backUrl: '/user'
        }
    },
    // 等级中心
    {
        path: '/user/level',
        name: 'level',
        component: () => import('pages/user/level/level'),
        meta: {
            navBar: true,
            title: '等级中心',
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 邀请好友
    {
        path: '/user/invite',
        name: 'invite',
        component: () => import('pages/user/invite/invite'),
        meta: {
            navBar: true,
            title: '邀请好友',
            Auth: true
        }
    },
    // 我的目标
    {
        path: '/user/target',
        name: 'target',
        component: () => import('pages/user/target/index/index'),
        meta: {
            navBar: true,
            title: '我的目标',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 我的目标详情
    {
        path: '/user/target/show/:id',
        name: 'targetShow',
        component: () => import('pages/user/target/show/show'),
        meta: {
            navBar: true,
            title: '我的目标',
            Auth: true
        }
    },
    // 我的兴趣
    {
        path: '/user/interest',
        name: 'interest',
        component: () => import('pages/user/interest/interest'),
        meta: {
            navBar: true,
            title: '我的兴趣',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 收货地址
    {
        path: '/user/address',
        name: 'address',
        component: () => import('pages/user/address/index/index'),
        meta: {
            navBar: true,
            title: '收货地址',
            Auth: true
        }
    },
    // 编辑收货地址
    {
        path: '/user/address/edit/:id',
        name: 'editAddress',
        component: () => import('pages/user/address/edit/edit'),
        meta: {
            navBar: true,
            title: '编辑地址',
            Auth: true
        }
    },
    // 新增收货地址
    {
        path: '/user/address/create',
        name: 'createAddress',
        component: () => import('pages/user/address/create/create'),
        meta: {
            navBar: true,
            title: '添加地址',
            Auth: true
        }
    },
    // 兑换记录
    {
        path: '/user/record',
        name: 'record',
        component: () => import('pages/user/record/index/index'),
        meta: {
            navBar: true,
            title: '订单中心',
            Auth: true
        }
    },
    // 订单发货
    {
        path: '/order/record/show/:id',
        name: 'recordShow',
        component: () => import('pages/user/record/show/show'),
        meta: {
            navBar: true,
            title: '订单详情',
            Auth: true
        }
    },
    // 物流
    {
        path: '/order/record/logistics/:id',
        name: 'taskManagementLogistics',
        component: () => import('pages/user/record/logistics/logistics'),
        meta: {
            navBar: true,
            title: '物流跟踪',
            Auth: true
        }
    },

    // 历史记录
    {
        path: '/user/history',
        name: 'history',
        component: () => import('pages/user/history/history'),
        meta: {
            navBar: true,
            title: '历史记录',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 帮助与反馈
    {
        path: '/user/help',
        name: 'help',
        component: () => import('pages/user/help-feedback/index/index'),
        meta: {
            navBar: true,
            title: '帮助中心',
            Auth: false

        }
    },
    // 帮助与反馈详情
    {
        path: '/user/help/show/:id',
        name: 'helpShow',
        component: () => import('pages/user/help-feedback/show/show'),
        meta: {
            navBar: true,
            Auth: false,
            title: '帮助中心',
        }
    },
    // 关于我们
    {
        path: '/user/about',
        name: 'about',
        component: () => import('pages/user/about/index/index'),
        meta: {
            navBar: true,
            title: '关于我们',
            Auth: false
        }
    },
    // 关于我们详情
    {
        path: '/user/about/show/:id',
        name: 'aboutShow',
        component: () => import('pages/user/about/show/show'),
        meta: {
            navBar: true,
            Auth: false,
            title: '关于我们',
        }
    },
    // 战队
    {
        path: '/user/team',
        name: 'team',
        component: () => import('pages/user/team/index'),
        meta: {
            navBar: true,
            title: '战队',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 战队详情
    {
        path: '/user/team/show/:id',
        name: 'teamShow',
        component: () => import('pages/user/team/show/show'),
        meta: {
            navBar: true,
            title: '战队详情',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 消息通知
    {
        path: '/user/message/:id',
        name: 'message',
        component: () => import('pages/user/message/message'),
        meta: {
            navBar: true,
            title: '消息通知',
            Auth: false
        }
    },
    // 精彩推荐详情
    {
        path: '/user/recommend/:id',
        name: 'recommend',
        component: () => import('pages/user/recommend/recommend'),
        meta: {
            navBar: true,
            Auth: false,
            title: '精彩推荐'
        }
    },
    // 错误
    {
        path: '/error',
        name: 'error',
        component: () => import('pages/error/error'),
        meta: {
            Auth: false
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name:'*.error',
        component: () => import('pages/error/error'),
    },

    // 微信打开提示
    {
        path: '/wechat-hint',
        name: 'wechat-hint',
        component: () => import('pages/user/WechatHint/WechatHint'),
    },
    // 系统维护中
    {
        path: '/system',
        name: 'system',
        component: () => import('pages/system/System')
    },

    // 加入社群
    {
        path: '/join',
        name: 'join',
        component: () => import('pages/user/join-community/JoinCommunity'),
        meta: {
            title: '加入社群',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 加入战队待审核
    {
        path: '/audit',
        name: 'audit',
        component: () => import('pages/user/audit/Audit'),
        meta: {
            title: '待审核',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 大队长 小队管理
    {
        path: '/management-team',
        name: 'managementTeam',
        component: () => import('pages/user/management-team/Index'),
        meta: {
            navBar: true,
            title: '小队管理',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 编辑小队
    {
        path: '/management-team/edit/:id',
        name: 'managementTeam.edit',
        component: () => import('pages/user/management-team/Edit'),
        meta: {
            navBar: true,
            title: '编辑小队',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    {
        path: '/management-team/create',
        name: 'managementTeam.create',
        component: () => import('pages/user/management-team/Create'),
        meta: {
            navBar: true,
            title: '创建小队',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 小队队员展示
    {
        path: '/management-team/team/:id',
        name: 'managementTeam.team',
        component: () => import('pages/user/management-team/Team'),
        meta: {
            navBar: true,
            title: '',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 小队长 小队管理
    {
        path: '/management-team/show',
        name: 'management-team.show',
        component: () => import('pages/user/management-team/TeamShow'),
        meta: {
            navBar: true,
            title: '',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 队伍统计
    {
        path: '/team/stat',
        name: 'team.stat',
        component: () => import('pages/user/team-data/Index'),
        meta: {
            navBar: true,
            title: '队伍统计',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 新增人数
    {
        path: '/team/stat/add',
        name: 'team.stat.add',
        component: () => import('pages/user/team-data/children/Add'),
        meta: {
            navBar: true,
            title: '新增人数',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 未完成
    {
        path: '/team/stat/freshers',
        name: 'team.stat.freshers',
        component: () => import('pages/user/team-data/children/Freshers'),
        meta: {
            navBar: true,
            title: '新手未做',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 未完成
    {
        path: '/team/stat/unfinished',
        name: 'team.stat.unfinished',
        component: () => import('pages/user/team-data/children/Unfinished'),
        meta: {
            navBar: true,
            title: '未做',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 已完成
    {
        path: '/team/stat/finished',
        name: 'team.stat.finished',
        component: () => import('pages/user/team-data/children/Finished'),
        meta: {
            navBar: true,
            title: '已做',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 未审核
    {
        path: '/team/stat/audit',
        name: 'team.stat.audit',
        component: () => import('pages/user/team-data/children/Audit'),
        meta: {
            navBar: true,
            title: '未审核',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 已审核
    {
        path: '/team/stat/audited',
        name: 'team.stat.audited',
        component: () => import('pages/user/team-data/children/Audited'),
        meta: {
            navBar: true,
            title: '已审核',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        }
    },
    // 我的小组
    {
        path: '/user/group',
        name: 'user.group',
        component: () => import('pages/user/group/index'),
        meta: {
            navBar: true,
            title: '我的小组',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 小组打分
    {
        path: '/user/group/score',
        name: 'user.score',
        component: () => import('pages/user/group/score/score'),
        meta: {
            navBar: true,
            title: '小组打分',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 小组打卡
    {
        path: '/user/group/card',
        name: 'user.card',
        component: () => import('pages/user/group/card/card'),
        meta: {
            navBar: true,
            title: '学习打卡',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 小组打卡列表
    {
        path: '/user/group/list',
        name: 'user.card.list',
        component: () => import('pages/user/group/card/list'),
        meta: {
            navBar: true,
            title: '',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 小组打卡详情
    {
        path: '/user/group/card/info',
        name: 'user.card.info',
        component: () => import('pages/user/group/card/info'),
        meta: {
            navBar: true,
            title: '学习打卡',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 社区
    {
        path: '/community',
        name: 'community.index',
        component: () => import('pages/community/index/index'),
        meta: {
            tabBar: true,
            title: '社区',
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
     // 悬赏详情
     {
        path: '/community/show',
        name: 'community.show',
        component: () => import('pages/community/show/show'),
        meta: {
            navBar: true,
            title: '悬赏问答',
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
     // 悬赏新增
     {
        path: '/community/create',
        name: 'community.create',
        component: () => import('pages/community/create/create'),
        meta: {
            navBar: true,
            title: '悬赏问答',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 跳蚤商品详情
    {
        path: '/community/market-show',
        name: 'community.market-show',
        component: () => import('pages/community/market-show/market-show'),
        meta: {
            navBar: true,
            title: '跳蚤商品',
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
     // 跳蚤新增
     {
        path: '/community/market-create',
        name: 'community.market-create',
        component: () => import('pages/community/market-create/market-create'),
        meta: {
            navBar: true,
            title: '跳蚤商品',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 我的发布
    {
        path: '/user/release',
        name: 'user.release',
        component: () => import('pages/user/release/release'),
        meta: {
            navBar: true,
            title: '我的发布',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 我的出售
    {
        path: '/user/sell',
        name: 'user.sell',
        component: () => import('pages/user/sell/sell'),
        meta: {
            navBar: true,
            title: '我的出售',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 我的收藏
    {
        path: '/user/collection',
        name: 'user.collection',
        component: () => import('pages/user/collection/collection'),
        meta: {
            navBar: true,
            title: '我的收藏',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 我的浏览
    {
        path: '/user/browse',
        name: 'user.browse',
        component: () => import('pages/user/browse/browse'),
        meta: {
            navBar: true,
            title: '我的浏览',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 我的设置
    {
        path: '/user/setup',
        name: 'user.setup',
        component: () => import('pages/user/setup/setup'),
        meta: {
            navBar: true,
            title: '我的设置',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },

    // 我的消息
    {
        path: '/user/new',
        name: 'user.new',
        component: () => import('pages/user/new/new'),
        meta: {
            navBar: true,
            title: '我的消息',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
 // 发货
 {
    path: '/user/order/deliver',
    name: 'user.order.deliver',
    component: () => import('pages/user/order/deliver/deliver'),
    meta: {
        navBar: true,
        title: '发货',
        Auth: true,
        userUpdate: true, // 用户接口是否需要更新
    },
},
 // 搜索
 {
    path: '/community/search',
    name: 'community.search',
    component: () => import('pages/community/search/search'),
    meta: {
        navBar: true,
        title: '社区',
        Auth: false,
        userUpdate: true, // 用户接口是否需要更新
    },
},
 // im
 {
    path: '/user/im',
    name: 'user.im',
    component: () => import('pages/user/im/im'),
    meta: {
        navBar: true,
        title: '酸谈',
        Auth: true,
        userUpdate: true, // 用户接口是否需要更新
    },
},
{
    path: '/upload',
    name: 'upload',
    component: () => import('pages/upload/index'),
    meta: {
        title: '酸谈',
        Auth: false,
    },
},
    // 电子资源详情
    {
        path: '/community/resources-show',
        name: 'community.resources-show',
        component: () => import('pages/community/resources-show/resources-show'),
        meta: {
            navBar: true,
            title: '资源互助',
            Auth: false,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 电子资源编辑
    {
        path: '/community/resources-create',
        name: 'community.resources-create',
        component: () => import('pages/community/resources-create/resources-create'),
        meta: {
            navBar: true,
            title: '资源互助',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 我的下载
    {
        path: '/user/download',
        name: 'user.download',
        component: () => import('pages/user/download/download'),
        meta: {
            navBar: true,
            title: '我的下载',
            Auth: true,
            userUpdate: true, // 用户接口是否需要更新
        },
    },
    // 积分规则
    {
        path: '/task/integral',
        name: 'task.integral',
        component: () => import('pages/task/integral/integral'),
        meta: {
            navBar: true,
            title: '积分规则',
            Auth: true,
        },
    },
    //message  ranking  study
]
