<template>
  <div>
    <van-popup v-model:show="mobile_phone_static" :round="true"  :close-on-click-overlay="false" z-index="9999">
      <div class="phone-wrap">
        <div class="phone-tit">绑定手机</div>
        <div class="inp-item">
          <div class="inp-list vertical-center">
            <img src="~assets/images/index/phone-icon.png" class="inp-icon" alt="">
            <input type="tel" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="请填写您的手机号码" @blur="blurFun" v-model="mobile" maxlength="11" class="inp-text" >
          </div>
          <div class="inp-list vertical-center">
            <img src="~assets/images/index/phone-code-icon.png" class="inp-icon" alt="">
            <div class="code-hint" v-show="discount_end_at" >请先获取验证码</div>
            <input type="tel" v-show="!discount_end_at" oninput="if(value.length>6)value=value.slice(0,6)" placeholder="请输入手机验证码" @blur="blurFun" v-model="code" maxlength="6" class="inp-text">

            <div class="code-btn" :class="{'code-btn-default' : mobile.trim().length != 11 || !discount_end_at}" @click="getCode">
              {{ discount_end_at ? '获取验证码' : last_time + '秒后重新发送' }}
            </div>
          </div>
        </div>
        <div class="inp-btn" :class="{'forbid-bg': mobile.trim().length !=11 || code.trim().length != 6}" @click="submitFUn">确定</div>
      </div>
    </van-popup>

    <van-overlay :show="login_loading_show" z-index="999999">
      <div class="loading-wrap">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>

</template>

<script>
import error from "pages/error/error";

export default {
  name: "MobilePhone",
  data() {
    return {
      mobile: '',
      code: '',
      discount_end_at: true,
      last_time: 120,
      countdown: 120,
      timer: null,
      codeInfo: null,
      mobile_send_status: true,
      login_loading_show: false,
      mobile_phone_static: true
    }
  },
  created() {
  },
  methods: {
    //  获取手机号
    getCode() {
      let _this = this;
      if (this.mobile.trim().length == 11) {
        if (this.discount_end_at) {
          clearInterval(this.timer)
          this.$http.post(this.$api.send, {mobile: this.mobile.trim()}).then(res => {
            if (res.data.success) {
              this._setTime();
              this.discount_end_at = false;
              this.codeInfo = res.data.data;
            } else {
              this.$toast(res.data.message)
            }
          }).catch((error)=>{
            if (error.response.status == 422) {
              this.$toast(error.response.data.message);
            }
          })
        }
      }
    },
    // 提交验证
    submitFUn() {
      let _this = this;
      if (this.mobile.trim().length == 11 && this.code.trim().length == 6) {
        if(_this.codeInfo == null){
          return false;
        }
        if(!_this.mobile_send_status){
          return false
        }
        _this.mobile_send_status = false;
        let data = {
          from: 'wx',
          verify_code: this.code,
          verify_key: this.codeInfo.key,
          open_id: this.openid
        };
        _this.login_loading_show = true;
        this.$http.post(this.$api.Mobile, data).then(res => {
          if (res.data.success) {
            _this.login_loading_show = false;
            this.$toast('登录成功');
            this.$cache.setLoginInfo(res.data.data);
            this.$cache.setExpiresIn(res.data.data.expires_in);
            setTimeout(()=>{
              window.location.reload()
            },1500)
          } else {
            _this.login_loading_show = false;
            this.$toast(res.data.message)
          }
          setTimeout(()=>{
            _this.mobile_send_status = true
          },500)
        }).catch(error => {
          _this.login_loading_show = false;
          if (error.response.status == 422) {
            this.$toast(error.response.data.message);
          }
          setTimeout(()=>{
            _this.mobile_send_status = true
          },500)
        })
      }
    },
    //  倒计时
    _setTime() {
      this.timer = setInterval(() => {
        if (this.countdown == 0) {
          this.last_time= 120;
          this.countdown= 120;
          this.discount_end_at = true;
          this.code = '';
          this.codeInfo = null;
          this.mobile_send_status = true;
          clearInterval(this.timer)
          return
        }
        this.countdown--;
        this.last_time = this.countdown;
      }, 1000)
    },
    // 失焦方法
    blurFun(){
      window.scroll(0, 0);
    }
  },
  computed: {
    openid() {
      return this.$store.getters.openid
    }
  }

}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.phone-wrap {
  width: 600/$r;
  height: 532/$r;
  background: #FFFFFF;
  border-radius: 10/$r;
  box-sizing: border-box;
  padding-top: 46/$r;
  padding-bottom: 50/$r;

  .phone-tit {
    font-size: 32/$r;
    line-height: 32/$r;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-bottom: 60/$r;
  }

  .inp-item {
    margin: 0 50/$r;

    .inp-list {
      padding-bottom: 40/$r;
      border-bottom: 1px solid #D9D9D9;
      margin-top: 40/$r;
      position: relative;

      .inp-icon {
        width: 46/$r;
        height: 46/$r;
        display: block;
        margin-right: 26/$r;
      }

      .inp-text {
        flex: 1;
        height: 46/$r;
        line-height: 46/$r;
        font-size: 24/$r;
        color: #313A43;
        outline: none;
        display: block;
        border: none;
      }
      .inp-text::-webkit-input-placeholder {
        color: #999999;
      }
      .inp-text:-moz-placeholder {
        color: #999999;
      }
      .inp-text:-ms-input-placeholder {
        color: #999999;
      }
      .code-hint{
        flex: 1;
        height: 46/$r;
        line-height: 46/$r;
        font-size: 24/$r;
        color: #BFBFBF;
        outline: none;
        display: block;
        border: none;
      }

      .code-btn {
        width: 190/$r;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #FF6B29;
        border-radius: 5/$r;
        font-size: 24/$r;
        line-height: 24/$r;
        font-weight: 400;
        color: #FF6B29;
        padding: 20/$r 0;
        position: absolute;
        top: -10/$r;
        right: 0;
      }

      .code-btn-default {
        color: #BFBFBF;
        border: 1px solid #BFBFBF;
      }
    }

  }

  .inp-btn {
    width: 460/$r;
    height: 80/$r;
    line-height: 80/$r;
    text-align: center;
    background: #FF6B29;
    border-radius: 40/$r;
    color: #FFFFFF;
    font-size: 28/$r;
    margin: 54/$r auto 0 auto;
  }
}

.loading-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


</style>
<style>
.van-toast{
  z-index: 99999!important;
}
</style>
