<template>
<div class="BulletFrame">
    <van-overlay :show="is_showTsak === 'Y'" z-index="999" class="wrapper">
<!--    <van-overlay :show="is_showTsak === 'Y'" z-index="999" class="wrapper">-->
        <div class="wrapper">
            <div class="BulletFrame-con">
                <div class="bulletFrame-tit">
                    <div class="bulletFrame-tit-img-con">
                    </div>

                    <div class="bulletFrame-tit-text">系统提示</div>
                    <div class="bulletFrame-tit-img-con">
                        <img class="bulletFrame-tit-img" src="~assets/images/index/close-icon.png" alt="" @click="closeOver">

                    </div>
                </div>
                <div class="bulletFrame-body vertical-center" v-if="isShowBullet">
                    <div class="bulletFrame-main flex">
                        <img class="bulletFrame-main-img" src="~assets/images/index/tip-icon.png" alt="">
                        <div class="bulletFrame-main-tit">{{ userInfo.is_team_master == 1 || userInfo.is_group_master == 1 ? '当前审核' : '上周任务'}}进度</div>
                        <div class="bulletFrame-tip flex">
                            <img class="bulletFrame-tip-icon" src="~assets/images/index/complete-icon.png" alt="">
                            <div class="bulletFrame-tip-text">已审核：<span>{{ $handle.tranNumber(taskEjectData.checked_number,3) }}个</span></div>
                        </div>
                        <div class="bulletFrame-tip flex">
                            <img class="bulletFrame-tip-icon" src="~assets/images/index/more.png" alt="">
                            <div class="bulletFrame-tip-text">待审核：<span>{{ $handle.tranNumber(taskEjectData.checking_number,3) }}个</span></div>
                        </div>
                    </div>
                    <div class="bulletFrame-know align-center" @click="closeOver">知道了</div>
                </div>
            </div>
        </div>
    </van-overlay>
</div>
</template>

<script>
export default {
    name: "BulletFrame",
    data(){
        return {
            is_showTsak: 'N',
            taskEjectData: {},
            package_id: 0,
            isShowBullet: false,
        }
    },
    created() {
        this.getTaskEject()
    },
    methods:{
        // 获取信息
        getTaskEject(){
            let url = this.$api.UserTaskEject
            this.$http.get(url,true).then( res => {
                if(res.data.success){
                    this.taskEjectData = res.data.data
                    this.package_id = res.data.data.package_id
                    this.is_showTsak = res.data.data.is_eject
                    this.isShowBullet = true
                }
            })
        },
        //关闭弹窗
        closeOver(){
            let url = this.$api.UserTaskEject
            let data = {
                package_id: this.package_id
            }
            this.$http.post(url,data,true).then( res => {
                if(res.data.success){
                   this.getTaskEject()
                }else {
                    this.is_showTsak = 'N'
                }
            })
        }
    },
    watch:{
        userInfo(val, oldVal){
            return val;
        }
    },
    computed:{
        userInfo(){
            return this.$store.getters.userInfo;
        }
    }
}
</script>

<style scoped lang="scss">
$r: 750/16/1rem;
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .BulletFrame-con {
        width: 600/$r;
        height: 664/$r;
        background: #FFFFFF;
        border-radius: 20/$r;
        padding: 35/$r 40/$r;
        box-sizing: border-box;
        .bulletFrame-tit{
            //position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .bulletFrame-tit-text{
                font-size: 32/$r;
                font-family: PingFang SC;
                font-weight: 500;
                color: #1A1A1A;
                line-height: 32/$r;
            }
            .bulletFrame-tit-img-con{
                width: 35/$r;
                height: 34/$r;
            }
            .bulletFrame-tit-img{
                position: absolute;
                //top: -1/$r;
                //right: 0/$r;
                display: block;
                width: 35/$r;
                height: 34/$r;
            }
        }
        .bulletFrame-body{
            flex-direction: column;
            margin-top: 44/$r;
            .bulletFrame-main{
                flex-direction: column;
                .bulletFrame-main-img{
                    display: block;
                    width: 180/$r;
                    height: 187/$r;
                    margin: 0 auto;
                }
                .bulletFrame-main-tit{
                    margin: 30/$r auto 28/$r;
                    font-size: 32/$r;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 36/$r;
                }
                .bulletFrame-tip{
                    margin-bottom: 30/$r;
                    .bulletFrame-tip-icon{
                        display: block;
                        width: 40/$r;
                        height: 40/$r;
                        margin-right: 20/$r;
                    }
                    .bulletFrame-tip-text{
                        font-size: 30/$r;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 36/$r;
                        span{
                            color: #FF6B29;
                        }
                    }
                }
            }
            .bulletFrame-know{
                width: 460/$r;
                height: 80/$r;
                background: #FF6B29;
                border-radius: 40/$r;
                margin-top: 20/$r;
                font-size: 28/$r;
                font-family: Source Han Sans CN;
                font-weight: 300;
                line-height: 1;
                color: #FFFFFF;
                text-align: center;
            }
        }
    }
}


</style>
