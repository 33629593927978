<template>
<div>
<!-- 登录授权提示开始 -->
  <van-overlay :show="loginPop" z-index="9999" @click="closeOverlay">
    <div class="login-hint-wrap" @click.stop>
      <img src="~assets/images/login/login-hint-bg.png" class="login-hint-bg" alt="">
      <img src="~assets/images/login/login-hint-icon.png" class="login-hint-icon" alt="">
      <div class="hint-box">
        <p class="hint-login-p">您还没登录</p>
        <p class="hint-login-text">请先登录再进行此操作</p>
        <div class="login-btn" @click="Login">立即登录</div>
      </div>
    </div>
  </van-overlay>
<!-- 登录授权提示结束 -->
  <div v-if="mobilePhone">
    <mobile-phone @mobile_hone="mobileHone"></mobile-phone>
  </div>

  <van-overlay :show="loginLoading" z-index="9999">
    <div class="loading-wrap">
      <van-loading type="spinner" />
    </div>
  </van-overlay>
</div>
</template>

<script>
import MobilePhone from './MobilePhone'
export default {
  name: "LoginBounced",
  data() {
    return{
      login_hint_status: false,  // 授权提示框
      mobile_phone_static: false, // 绑定手机号
      login_loading_show: false
    }
  },
  created() {
  },
  methods: {
    /*登录*/
    Login() {
      this.$weChat.wechat()
      // window.history.go(-1)
    },
    //  手机号绑定结果
    mobileHone(data) {
      let _this = this;
      if(data.access_token){
        _this.$toast('登录成功');
      }
      _this.$emit('loginBack',data)
    },
    closeOverlay(){
      this.$store.commit('M_LOGIN_POP', false)
      this.$router.push('/')
    }
  },
  computed: {
    // 登录提示框
    loginPop() {
      return this.$store.getters.loginPopStatus;
    },
    // 手机号绑定弹框
    mobilePhone() {
      return this.$store.getters.mobilePhone;
    },
    // 加载提示框
    loginLoading() {
      return this.$store.getters.loginLoading;
    }
  },
  components: {
    MobilePhone
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/login/login-pop.scss";
.loading-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
