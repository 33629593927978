import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store/store'
import api from './http/api';
import http from './http/request'
import Util from "./utils/util"
import handle from "./utils/handle";
import WeChat from "./utils/WeChat"
import User from "./utils/user"
import Cache from "./utils/cache"
import carbon from "@/utils/carbon";
import localStorage from "./utils/localStorage";
import wxShare from "@/utils/wxShare";
import confStatic from '/src/config/static';
import regular from "@/config/regular";
import bigNumberTransform from "./utils/bigNumberTransform";
import VConsole from 'vconsole';

// vant 引入
import { Tabbar, TabbarItem } from 'vant';
import { Button } from 'vant';
import { Progress } from 'vant';
import { Image as VanImage } from 'vant';
import { Popup } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { SwipeCell } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Divider } from 'vant';
import { DatetimePicker } from 'vant';
import { Loading } from 'vant';
import { Field } from 'vant';
import { Switch } from 'vant';
import { Uploader } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Col, Row } from 'vant';
import { Grid, GridItem } from 'vant';
import { Overlay } from 'vant';
import { Toast } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { List } from 'vant';
import { Dialog } from 'vant';
import { Form } from 'vant';
import { Area } from 'vant';
import { Cell, CellGroup } from 'vant';
import { NavBar } from 'vant';
import { Step, Steps } from 'vant';
import { Sidebar, SidebarItem } from 'vant';
import { ImagePreview } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Picker } from 'vant';
import { Rate } from 'vant';
import { ActionSheet } from 'vant';
import { Stepper } from 'vant';
import { Search } from 'vant';
import Avatar from 'vue-avatar'
import { Popover } from 'vant';

// 拦截请求响应
import './http/axios'

const app = createApp(App);

app.use(Swipe);
app.use(SwipeItem);
app.use(Popup);
app.use(Button);
app.use(Progress);
app.use(VanImage);
app.use(Tab);
app.use(Tabs);
app.use(SwipeCell);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Divider);
app.use(DatetimePicker);
app.use(Loading);
app.use(Field);
app.use(Switch);
app.use(Uploader);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Col);
app.use(Row);
app.use(Grid);
app.use(GridItem);
app.use(Overlay);
app.use(Row);
app.use(Toast);
app.use(Radio);
app.use(RadioGroup);
app.use(List);
app.use(Dialog);
app.use(Form);
app.use(Area)
app.use(Cell);
app.use(CellGroup);
app.use(NavBar);
app.use(Step);
app.use(Steps);
app.use(Sidebar);
app.use(SidebarItem);
app.use(ImagePreview);
app.use(Collapse);
app.use(CollapseItem);
app.use(DropdownMenu);
app.use(router);
app.use(VueAxios,axios);
app.use(store);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Picker);
app.use(Rate);
app.use(ActionSheet);
app.use(Stepper);
app.use(Search);
app.use(Popover);

app.config.globalProperties.$util = Util;
app.config.globalProperties.$cache = Cache;
app.config.globalProperties.$carbon = carbon;
app.config.globalProperties.$api = api;
app.config.globalProperties.$http = http;
app.config.globalProperties.$handle = handle;
app.config.globalProperties.$weChat = WeChat;
app.config.globalProperties.$user = User;
app.config.globalProperties.$localStorage = localStorage;
app.config.globalProperties.$wxShare = wxShare;
app.config.globalProperties.$confStatic = confStatic;
app.config.globalProperties.$regular = regular;
app.config.globalProperties.$bigNumberTransform = bigNumberTransform;

app.component('Avatar',Avatar)
app.mount('#app');

app.config.ignoredElements = ['wx-open-launch-weapp']

if (process.env.VUE_APP_VS_CONSOLE_IS_SHOW === 'true') {
    new VConsole();
}
// 系统维护
if(process.env.VUE_APP_SYSTEM == 'off'){
    router.replace({path: '/system'})
}

// 微信浏览器打开提示
if(process.env.VUE_APP_WEIXIN == 'on'){
    if(!handle.isWeiXin()){
        router.replace({path: '/wechat-hint'})
    }
}


// // // 手机控制太打印
// import Vconsole from 'vconsole'
// if(process.env.VUE_APP_ENV == 'development'){
//
// }
// let vConsole = new Vconsole()
// app.use(vConsole)


// 登录信息跳转
import './router/util'

import './utils/refresh'






