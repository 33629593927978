import {Base64} from 'js-base64';

import urlEncode from 'urlencode'


export default {
    /*
    * 数据加密
    * data => obj 格式
    * */
    encryption(data) {
        return Base64.encode(urlEncode((JSON.stringify(data)))).replace(/=/g, '');
    },

    /*
    * 数据解密
    * str 字符串
    * */
    decrypt(str) {
        try {
            return JSON.parse(urlEncode.decode(Base64.decode(str)));
        } catch (e) {
            return false;
        }
    },

    urlEncode(data) {
        return urlEncode(data);
    },

    urlDecode(str) {
        return urlEncode.decode(str);
    },

    /**
     * 判断是否是微信浏览器的函数
     *
     * @returns {boolean}
     */
    isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();

        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 数字转整数 如 100000 转为10万
     * @num {需要转化的数} num
     * @point {需要保留的小数位数} point
     */
    tranNumber(num, point) {
        let numStr = num
        if(typeof num == 'number') {
            numStr = num.toString()
        }
        // 1万以内直接返回
        if (numStr.length < 5) {
            return numStr;
        }
        //大于4位数是万
        else if (numStr.length > 4) {
            let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
            return parseFloat(parseInt(num / 10000) + '.' + decimal) + 'w';
        }
    },
}
