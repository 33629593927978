import api from '../http/api';
import http from '../http/request';
import store from '../store/store';
import cache from "@/utils/cache";
import error from "pages/error/error";
import router from "@/router";
import { Toast } from 'vant';

export default {
    /*
     * 获取用户信息
     * */
    getUserInfo() {
        let _this = this;
        http.get(api.Users + '?getTime=' + new Date().getTime() , true).then(res => {
            if (res && res.data.success) {
                store.dispatch('A_USER_INFO', JSON.stringify(res.data.data));
                localStorage.setItem('userInfo',JSON.stringify(res.data.data));
                let userInfo = res.data.data;
                /*判断用户状态的*/
                if(localStorage.getItem('invite_code') && userInfo.status_team && userInfo.status_team.operate_status == 1){
                    _this.userInvites()
                    return false
                }
            }else {
                cache.removeLoginInfo();
                setTimeout(()=>{
                    router.replace({path: '/'})
                },1500)
            }
        })
    },

    /*
    * 微信登录
    * */
    weaChatLogin(code, callback) {
        let _this = this;
        let data = {
            from: 'wx',
            code: code
        };
        http.post(api.Auth,data).then( res => {
            if(res.data.success){
                if(res.data.data.access_token){
                    cache.setLoginInfo(res.data.data);
                    cache.setExpiresIn(res.data.data.expires_in);
                    _this.getUserInfo();
                }else if(res.data.data.openid) {
                    store.dispatch('A_USER_OPENID',res.data.data.openid)
                }
                return callback(res.data.data)
            }else {
                return callback([])
            }
        })
    },

    /*
    * 邀请加入接口
    * */
    userInvites(callback){
        let _this = this;
        let data = {
            invite_code: localStorage.getItem('invite_code')
        };
        http.post(api.Invites,data,true).then( res => {
            if(res.data.success){
                _this.getUserInfo();
                localStorage.removeItem('invite_code');
                return callback(true)
            }else {
                setTimeout(()=>{
                    _this.getUserInfo();
                },1500)
                Toast(res.data.message);
                localStorage.removeItem('invite_code');
                return callback(false)
            }
        })
    }
}
