import { createRouter, createWebHistory } from 'vue-router'
import routes from './routers'

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition && to.meta.keepAlive) { //keepAlive 配置缓存后的组件不处理回到顶部问题
    //         return savedPosition;
    //     }
    //     return { x: 0, y: 0 };
    // }
})

export default router

// vue重复点击路由报错
// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//     return routerPush.call(this, location).catch(error=> error)
// }
