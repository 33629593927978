module.exports= {
    'Auth': 'auth/auth',                        // 微信授权
    'Mobile': 'auth/mobile',                    // 绑定手机号
    'send': 'auth/send',                        // 发动手机号
    'Update': 'auth/update',                    // 刷新token
    'Users': 'user/users',                      // 用户信息
    'UserTags': 'user/tags',                    // 用户选择标签和目标
    'Tags': 'tags',                             // 用户获取标签和目标
    'Ads': 'ads',                               // 广告
    'Notices': 'notices',                       // 通知
    'Categories': 'goods/categories',           // 商品分类
    'Rules': 'reward/rules',                    // 配置
    'Goods': 'goods',                           // 积分商品列表
    'Tasks': 'tasks',                           // 获取任务列表 周任务 今日任务 限时任务
    'Relatives': 'relatives',                   // TA们在做
    'Groups': 'groups',                         // 学习小组
    'Ranks': 'ranks',                           //用户排行榜
    'Teams': 'teams',                           //战队排行榜
    'JoinTasks': 'user/tasks',                  //加入战队
    'Userranks': 'user/ranks',                  //用户战队信息
    'Members': 'members',                       //战队队友列表
    'Receptions': 'user/receptions',            //签到
    'Targets':'user/targets',                   //新增小目标
    'UpdateTag': 'user/update_target_tag',      //更新大目标
    'UpdateTagTime': 'user/update_target_time', //更新大目标时间
    'SubTarget': 'user/sub_target',             //更新大目标时间
    'Recommends': 'recommends',                 // 推荐列表
    'Address': 'user/address',                  // 用户收货地址列表
    'Uploads': 'user/uploads',                  // 文件上传
    'Invites': 'user/invites',                  // 邀请信息
    'Accounts': 'user/accounts',                // 历史记录
    'Interests': 'user/interests',              // 用户兴趣
    'UserTasks': 'user/tasks',                  // 任务上传完成
    'UserLevel': 'user/level',                  // 用户等级中心
    'Contents': 'contents',                     // 关于我们 帮助反馈
    'Tasknot': 'user/task-notifications',       // 任务通知
    'Teamnot': 'user/team-notifications',       // 战队通知
    'Notifications': "user/notifications",      // 队长处理任务
    'OrdersLog': 'orders/log',                  // 商品兑换记录
    'OrdersConfirm': 'orders/confirm',          // 商品兑换查询
    'OrdersStore': 'orders/store',              // 提交兑换
    'OrdersQr': 'orders/qr',                    // 获取训练营二维码
    'Order': 'orders/index',                    // 订单列表
    'OrderCredit': 'community/flea-credit',             // 积分记录
    'OrderUpdate': 'orders/update',             // 确认收货
    'Signature': 'signature',                   // 微信分享
    'OrdersShow': 'orders/show',                // 订单详情
    'OrdersUpdate': 'orders/update',            // 确认收货
    'Logistics': 'logistics',                   // 物流查询
    'Slides': 'slides',                         // 幻灯片
    'UserEject': 'user/eject',                  // 用户结算接口
    'UserTaskEject': 'user/task-eject',         // 用户结算接口
    'TeamGroups': 'user/team-groups',          // 小队管理列表 // 小队添加
    'imageUpload': 'image-upload',             // 小队上传图片
    'teamGroups': 'user/team-groups',          // 分队删除的
    'UserTeamTags': 'user/team-tags',           //小队名称列表
    'StudyConfigs': 'configs',                  //小组配置
    'userNembers': 'user/members',              //小队成员
    'teamSet': 'user/team-set',                 //小队成员变更【取消队长 转队 设置队长
    'teamTags': 'user/team-tags',                // 小队列表名称
    'groupAll': 'user/group-all',                // 小队列表名称
    'groupMembers': 'user/group-members',        // 获取统计新增人数
    'groupPackages': 'user/group-packages',      // 获取统计完成和未完成的
    'groupTasks': 'user/group-tasks',           // 获取统计完成和未完成的
    'limitTasks': 'user/group-limit-tasks',            // 获取统计完成和未完成的
    'limitChecks': 'user/group-limit-checks',      // 获取审核完成和未完成的
    'Visits': 'user/visits',                    // 用户记录访问接口
    'TeamSearch': 'user/team-search',           // 创建小队搜索用户
    'userMembers': 'user/members',              // 搜索队员
    'groupLimits': 'user/group-limits',              // 任务列表
    'groupFreshers': 'user/group-freshers',                // 任务列表
    'Taskslimited': 'limited',  // 限时任务列表
    'evaluate':'evaluate',    //小组评价
    'myGroups':'my-groups',   //我的小组列表
    'punchClock':'punch-clock',    //我的小组笔记列表
    'award':'award',      //奖励条数
    'CommunityBounty':'community/bounty',     //悬赏-列表
  'Bounty':'bounty',     //悬赏-列表
  'detail':'/detail',       //悬赏-详情
  'Comment':'comment',    //评价
  'CommunityComment':'community/comment',    //评价
  'CommunityCategory':'category',  // 社区分类
  'communityPraise':'community/praise',    // 点赞
  'communityAdoption':'community/adoption',   //采纳
  'communityFlea':'community/flea',  // 跳蚤市场列表
  'Flea':'flea',  // 跳蚤市场列表
  'communityCollect':'community/collect', // 收藏
  'communityBrowse':'community/browse',  // 浏览
  'communityCommunitySearch':'community-search', //搜索
  'edit':'edit', // 编辑 
  'communityIm':'community/im', // im签名
  'communityFleaOrder':'community/flea-order', // 订单
  'communityFleaCancel':'community/flea-cancel', //订单取消
  'logisticsInformation':'logistics-information',//物流
  'communityFleaShip':'community/flea-ship',//发货
  'communityInformation':'community/information',//我的评论
  'communityFleasearch':'community/flea-search', // 订单搜索
  'communityFleareceipt':'community/flea-receipt', // 订单收货
  'communityFleaupdate':'community/flea-update',// 修改物流
  'communitybountypublish':'community/bounty/publish',  //发布
  'configs':'configs',  //配置
  'informationamount':'community/information-amount',//消息总数
  'communityordercount':'community/order-count', //订单消息
    'electron':'electron',            //电子资源列表
    'communityelectron':'community/electron',      //电子资源新增
    'uploadresource':'upload-resource',      // 上传资源
    'communityDownloadConfirm':'community/download-confirm',    //兑换
    'communityDownloadElectron':'community/download-electron',    //兑换
    'communityDownloadMine':'community/download-mine',     //我的下载
    'communityElectronUse':'community/electron-user',   //电子发布
    'integral':'integral',                         //积分规则
    'communityelectronpublish':'community/electron/publish',   //电子发布
    'fleaPublish':'community/flea/publish',     //跳蚤发布
}
