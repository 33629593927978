<template>
<div class="nav-bar-wrap">
  <van-nav-bar
      :title="title"
      left-text="返回"
      :placeholder="true"
      :fixed="true"
      left-arrow
      z-index="99"
      @click-left="onClickLeft"
  />
</div>
</template>

<script>
export default {
  name: "NavBar",
  data(){
    return{
      title: ''
    }
  },
  created() {
    if(this.metaTitle){
      this.title = this.metaTitle;
    }else {
      this.title = this.$route.meta.title;
    }
  },
  methods: {
    // 返回点击
    onClickLeft(){
      if(this.$route.meta.backUrl){
        if(this.$route.meta.backUrl.indexOf('?') != -1){
          window.location.href = window.location.origin + this.$route.meta.backUrl
        }else {
          this.$router.push({path: this.$route.meta.backUrl})
        }
      }else {
        this.$router.back();
      }
    }
  },
  watch: {
    metaTitle(val,oldval){
      this.title = val;
      return val
    }
  },
  computed: {
    metaTitle(){
      return this.$store.getters.title
    }
  }
}
</script>

<style lang="scss">
$r: 750/16/1rem;
.van-nav-bar__placeholder{
  height: 84/$r!important;
}
.van-nav-bar__content{
  height: 84/$r!important;
}
.van-nav-bar{
  line-height: 44/$r;
}
.van-nav-bar__left{
  font-size: 28/$r;
}
.van-nav-bar__arrow{
  font-size: 32/$r;
}
.van-nav-bar__text{
  color: #414141;
}
.van-nav-bar .van-icon{
  color: #414141;
}
.van-nav-bar__title{
  font-size: 32/$r;
  font-weight: 500;
  color: #262626;
}
.nav-bar-wrap{

}
</style>
