import axios from 'axios';
import store from "@/store/store";
import router from "@/router";
import cache from "@/utils/cache";
import Api from '@/http/api'

axios.interceptors.request.use(config => {
    config.url = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PREFIX + '/' + process.env.VUE_APP_API_VERSION + '/' + config.url;
    return config;
}, error => {
});

axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401) {
        cache.removeLoginInfo();
        return window.location.href = '/'
    } else if (error.response.status === 422) {
        return Promise.reject(error);
    } else if (error.response.status === 403) {
        return history.back(-1);
    } else {
        let msg = '未找到，您所请求的资源';
        switch (error.response.status) {
            case 429:
                msg = '你操作太频繁了';
                break;
            case 500:
                msg = '服务器内部错误';
                break;
            case 503:
                msg = '服务维护中';
                break;
            case 404:
                msg = '你所访问的页面不存在';
                break;
            default:
                msg = "I'm sorry, 出现了意外";
                break
        }
        /* 判断用户接口报错时*/
        if(error.response.config.url.indexOf(Api.Users) != -1){
            if (error.response.status == 500 || error.response.status == 401 || error.response.status == 403 || error.response.status == 406) {
                cache.removeLoginInfo()
            }
        }
        store.dispatch('A_ERROR_TEXT',{
            code_num: error.response.status,
            error_text: msg
        })
        setTimeout(() => {
            return router.push('/error');
        }, 200)
    }
});
